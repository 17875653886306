.modal-background {
  background-color: var(--secondary-bg);
  box-shadow: 0rem 0.3rem 0rem black !important;
  max-width: 320px;
}
.modal_button-background {
  background-color: #28a617 !important;
}
.modal_contact-number {
  border: 1px solid gray;
  background-color: aliceblue;
}
.modal_contact-address {
  border: 1px solid gray;
  background-color: aliceblue;
  height: 191px;
  max-width: 400px;
}
.contact-modal_button-2 {
  border: 1px solid #1375ed;
  background-color: aliceblue;
  color: #1375ed;
}
.contact-modal-background {
  background-color: var(--secondary-bg);
  box-shadow: 0rem 0.3rem 0rem black !important;
}
.radio-button-border input[type='radio'] {
  position: relative;
  appearance: none;
  -webkit-appearance: none;
  border: 2px solid #000;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  outline: none;
  margin-top: 0;
}
.modal_response-size {
  max-width: 30rem;
  height: 25rem;
}
