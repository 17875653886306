.driving-course-item__name {
  color: var(--tertiary-bg);
  top: -1rem;
  left: 0;
  right: -25rem;
  margin-left: auto;
  margin-right: auto;
  width: 20rem;
  background-color: var(--tertiary);
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  z-index: 1;
}
@media screen and (max-width: 992px) {
  .driving-course-item__name {
    left: 0;
    right: 0;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
  }
}
.driving-course-item__vehicle-image {
  width: stretch;
  height: auto;
  object-fit: cover;
}
.driving-course-item__info-badge {
  background: var(--tertiary);
  box-shadow: 0rem 0.25rem 0.625rem rgba(7, 71, 149, 0.2);
  color: white;
  border-radius: 0.625rem;
  align-items: center;
  width: fit-content;
}
.driving-course-item__info-badge img {
  height: 1.5rem;
}
.driving-course-item__info-badge span {
  font-size: 0.75rem;
}

@media screen and (max-width: 576px) {
  .driving-course-item__info-badge img {
    height: 1rem;
  }
  .driving-course-item__info-badge span {
    font-size: 0.65rem;
  }
}
.driving-course-item__price {
  color: #ea3c3c;
}

.driving-course-item__cta {
  background-color: var(--primary);
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.16);
  color: white;
}
