.blog-page__bg {
  background: #f7f7f7;
}

.blog-page__topic-row {
  background: var(--secondary);
}

.blog-page__card {
  border: 0.125rem solid var(--tertiary) !important;
  width: 25rem !important;
  height: 30rem !important;
  background: var(--secondary-bg) !important;
}

@media screen and (max-width: 576px) {
  .blog-page__card {
    width: 20rem !important;
    height: 30rem !important;
  }
}

.x-scroller {
  overflow-x: scroll;
  overflow-y: hidden;
}
