.ds-lead-form__card {
  border: 0.125rem solid black !important;
  box-shadow: 0rem 0.3125rem 0rem black !important;
  border-radius: 1.25rem !important;
  background: var(--secondary-bg) !important;
  position: relative !important;
  overflow: hidden !important;
}

.ds-lead-form__title {
  font-style: normal;
  color: var(--tertiary) !important;
  z-index: 1;
}
