.footer-form__heading {
  font-family: 'Poppins';
  color: var(--tertiary);
}
.footer-form__button {
  background-color: var(--primary);
}
@media screen and (max-width: 992px) {
  .mobile_form-background {
    background-color: #f3f4f6 !important;
  }
}
