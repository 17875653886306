.section-website-listing li {
  background-color: transparent;
  border: none;
}
.section-website-listing li img {
  width: 1rem;
}
.section-website-listing__card {
  box-shadow: 0rem 0.5rem 0rem black !important;
  border-radius: 3.75rem !important;
  background: var(--secondary-bg) !important;
}
.section-website-listing__card-title {
  box-sizing: border-box;
  border: 0.125rem solid black;
  background-color: #ffedce;
  border-radius: 3.75rem;
  font-style: normal !important;
  font-weight: 600 !important;
  color: var(--tertiary) !important;
}

.section-website-listing__card-footer {
  text-decoration: none;
  font-style: normal;
}
