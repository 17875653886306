.text-bar-icon {
  width: 5rem;
  height: 5rem;
  background-color: #d9d9d9;
}
.text-bar-icon img {
  width: 3rem;
  height: auto;
}

.text_bar_container {
  width: fit-content;
}
