.google-review-card {
  background-color: var(--tertiary-bg);
}
.google-review-card__user-image {
  height: 3.5rem;
  width: 3.5rem;
}
.google-review-card_badge {
  top: 45%;
  bottom: 0;
  left: 0;
  right: 0;
  margin-top: auto;
  margin-bottom: auto;
  height: auto;
}
.google-review-card_badge img {
  width: 9rem;
  height: fit-content;
}
/* Medium devices (landscape tablets, 992px and down) */
@media screen and (max-width: 992px) {
  .google-review-card_badge {
    top: 2rem;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
}
/* Medium devices (landscape tablets, 992px and down) */
@media screen and (max-width: 576px) {
  .google-review-card__user-image {
    height: 2.5rem;
    width: 2.5rem;
  }
  .google-review-card_badge {
    top: 2rem;
    left: 0;
    right: 0;
    margin-top: 0;
    margin-bottom: 0;
  }
  .google-review-card_badge img {
    width: 7rem;
    height: fit-content;
  }
}
