.driving-listing__card {
  box-shadow: 0rem 0rem 0.25rem rgba(0, 0, 0, 0.25);
}
.driving-listing__card-hero-img {
  display: block;
  height: 16rem;
  width: stretch;
  object-fit: cover;
}
.driving-listing__card-verified-badge {
  top: -0.1rem;
  left: -0.3rem;
  width: 11rem !important;
  height: auto !important;
}
/* Medium devices (landscape tablets, 992px and down) */
@media screen and (max-width: 992px) {
  .driving-listing__card-verified-badge {
    width: 10rem !important;
  }
  .driving-listing__card-hero-img {
    height: 14rem;
    margin: 0 !important;
    padding: 0 !important;
  }
}
@media screen and (max-width: 576px) {
  .driving-listing__card-verified-badge {
    width: 8rem !important;
  }
  .driving-listing__card-hero-img {
    height: 12rem;
    margin: 0 !important;
    padding: 0 !important;
  }
}

.driving-listing__card-rating_mobile {
  right: 0rem !important;
  top: 1rem;
  background-image: url(assets/listing/bg_rating_mobile.svg);
  background-repeat: no-repeat;
  background-size: cover;
  width: fit-content;
  justify-content: center !important;
  color: white;
  font-size: 0.75rem;
}
.driving-listing__card-rating {
  background-image: url(assets/listing/bg_rating.svg);
  background-repeat: no-repeat;
  background-size: contain;
  right: 2rem;
  height: 7rem;
  width: 3.5rem;
  color: white;
}
.driving-listing__card-google-rating {
  color: var(--primary);
}

.driving-listing__card-cta-btn {
  width: stretch;
  background: var(--primary);
  color: white;
}

.driving-listing__card-cost {
  align-items: center;
  color: #ea3c3c;
}
.driving-listing__card-services-button {
  display: inline-block;
  background: var(--tertiary);
  box-shadow: 0rem 0.25rem 0.625rem rgba(7, 71, 149, 0.2);
  border-radius: 0.625rem;
  color: white;
  align-items: center;
}
.driving-listing__card-services-button img {
  height: 1.2rem;
  padding-right: 0.5rem;
}
@media screen and (max-width: 576px) {
  .profile_mobile_font-size {
    font-size: 0.75rem !important;
    font-weight: 500 !important;
  }
}
