/* Search Button */
.search-form-button {
  width: stretch;
  height: 3.5rem;
  background: var(--primary);
  box-shadow: 0rem 0.275rem 0rem black !important;
}
.search-form-button:hover {
  transition: 0.8s;
  background-color: darkblue;
}
.search-form-button_label {
  font-style: normal;
  font-weight: 500;
  font-size: smaller;
  color: white;
}
.search-form-button_icon {
  height: 1.9rem;
  width: 1.9rem;
}

/* Locate Me Button */
.search-location-button {
  width: fit-content !important;
  height: 3rem;
  background: var(--secondary-bg);
  border: 0.0625rem solid #838383;
  right: 1.2rem;
  top: 0.25rem;
}
.search-location_icon {
  height: 1.3rem;
  width: 1.3rem;
  color: black;
}

.search-location_label {
  font-size: smaller;
  height: 1.5rem;
}
