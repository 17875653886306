:root {
  --primary: #1375ed;
  --secondary: #f9c56d;
  --secondary-light: #fedea7;
  --secondary-bg: #fbf4e6;
  --secondary-bg-variant: #fdf4e6;
  --tertiary: #272727;
  --tertiary-bg: #f3f3f3;
  --bs-body-font-family: 'Baloo 2';
}

.primary-button {
  box-sizing: border-box;
  height: auto;
  background-color: var(--primary);
  box-shadow: 0rem 0.25rem 0rem var(--tertiary);
  border: 0.0625rem;
  border-radius: 2.9375rem;
  color: white;
  font-family: 'Baloo 2';
  z-index: 1;
  position: relative;
}

.input-icon {
  height: 3.125rem;
  width: 3.75rem;
  background-color: white !important;
}

* img {
  pointer-events: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  -ms-user-select: none;
}

.z-1 {
  z-index: 1;
}

.z-0 {
  z-index: 0;
}
