.drively-footer {
  color: white;
  font-family: 'Poppins';
  font-style: normal;
  background-color: var(--tertiary);
}

.drively-footer__branding {
  text-align: start;
}
.drively-footer__branding > img {
  width: auto;
}
.drively-footer__branding > p {
  text-align: start;
}

.drively-footer__useful-links {
  text-align: start;
}

.drively-footer__useful-links > a:hover {
  color: #fff;
}
.drively-footer__useful-links > a {
  text-decoration: none;
  color: #fff;
  margin-bottom: 1rem;
}

.drively-footer__social-links {
  text-align: start;
}

/* Medium devices (landscape tablets, 992px and down) */
@media screen and (max-width: 992px) {
  .drively-footer__useful-links,
  .drively-footer__branding {
    border-bottom: white 0.0313rem solid;
    text-align: center;
  }
  .drively-footer__social-links {
    text-align: center;
  }
}
