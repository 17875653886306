.drively-footer2__social-links {
  text-align: start;
}

/* Medium devices (landscape tablets, 992px and down) */
@media screen and (max-width: 992px) {
  .drively-footer2__social-lg {
    text-align: center;
  }
}
