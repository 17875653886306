.testimonial-card {
  height: 25rem !important;
  width: stretch;
  border: 0.125rem solid black !important;
  box-shadow: 0rem 0.375rem 0rem black !important;
  border-radius: 1.875rem !important;
  background: var(--secondary-bg) !important;
  position: relative !important;
  overflow: hidden !important;
}
.testimonial-card_bg-top {
  width: 9rem;
  height: 9rem;
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-40%, -40%);
}
.testimonial-card_bg-bottom {
  width: 9rem;
  height: 9rem;
  position: absolute;
  bottom: 0;
  right: 0;
  transform: translate(40%, 40%);
}
.testimonial-card p {
  height: 10.7rem;
  overflow: hidden;
}
.testimonial-card h5 {
  text-align: center;
  justify-content: center;
}
.testimonial-card h6 {
  text-align: center;
  justify-content: center;
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 700;
}

.testimonial-img {
  border: 0.1875rem solid black;
  background-color: var(--secondary-bg) !important;
  position: absolute;
}
.testimonial-img > img {
  width: 5rem;
  height: 5rem;
  object-fit: cover;
}
