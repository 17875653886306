.yellow-hr {
  height: 0.6rem;
  background: var(--secondary);
  border-radius: 2rem;
}
@media screen and (max-width: 992px) {
  .yellow-hr {
    height: 0.25rem;
  }
}
