.listing-not-found img {
  height: 10%;
  width: auto;
  margin-top: -2rem;
}
.listing-not-found p {
  color: #565e6c;
}
.listing-not-found button {
  color: white;
  width: 14.1875rem;
  height: 3rem;
  left: 35.625rem;
  top: 41.59rem;
  background: var(--primary);
  border: none;
  border-radius: 0.625rem;
}

.listing-not-found__banner {
  background: #f3f4f6;
}
.listing-not-found__banner p {
  font-family: 'Poppins';
  color: var(--tertiary);
  width: fit-content;
}
.listing-not-found__banner_subtext {
  font-size: smaller !important;
  width: fit-content;
}

.listing-not-found__banner button {
  color: white;
  background: var(--primary);
  width: fit-content;
}
