.rating_card-background {
  background-color: #d9d9d9;
  height: 7.25rem;
  width: 18.438rem;
  opacity: 0.9;
}
.rating_button-background {
  background-color: #d9d9d9;
  border: 0;
}
@media screen and (max-width: 576px) {
  .rating_card-background {
    width: 100%;
  }
}
