.grow-biz_item-image {
  height: 2rem;
  width: 2rem;
}

.yellow-sm {
  height: 0.3rem;
  background: var(--secondary);
  border-radius: 2rem;
}
