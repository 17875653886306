.page-not-found img {
  height: 10%;
  width: auto;
  margin-top: -2rem;
}
.page-not-found p {
  color: #565e6c;
}
.page-not-found button {
  color: white;
  width: 14.1875rem;
  height: 3rem;
  left: 35.625rem;
  top: 41.59rem;
  background: var(--primary);
  border: none;
  border-radius: 0.625rem;
}
