/* --------navbar styling start-------------*/
.drively-navbar {
  color: white;
  box-shadow: 0rem 0.25rem 0.25rem rgba(0, 0, 0, 0.1);
  font-family: Poppins;
}
.drively-navbar__logo {
  width: auto;
  height: 2.7rem;
}
.drively-navbar__brand_name {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 800;
  font-size: 1.2rem;
  line-height: 0.625rem;
}

.nav-link {
  color: var(--tertiary) !important ;
  justify-self: center;
  text-align: center;
  position: relative;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 1.125rem;
}
.nav-link:hover {
  color: var(--primary) !important;
}
.nav-link.active {
  color: var(--primary) !important;
}
.nav-link.active::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 0.125rem;
  background-color: var(--primary);
  animation: animate 1s;
}

@keyframes animate {
  0% {
    transform: scaleX(0);
  }
  100% {
    transform: scaleX(1);
  }
}

/* --------navbar styling end-------------*/
