.qna-section_accordion-header button {
  background-color: white !important;
  color: black !important;
}
@media screen and (max-width: 576px) {
  .faq_mobile_font-size {
    font-size: 0.8rem !important;
    font-weight: 600;
  }
}
