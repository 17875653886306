.section-dark {
  background-color: var(--tertiary);
}

.section-light-dark {
  background-color: #525252;
}

.home-modal_body {
  background-color: #272727;
  opacity: 0.9;
  text-align: center;
}
.home-modal_close-button {
  position: absolute;
  top: 0.9375rem;
  right: 0.9375rem;
  color: #fff;
  background-color: transparent;
  border: none;
}
