/* Css for container 1 starts */
.learn-driving_container-1 {
  background: url('assets/learn_driving/bg_first.svg');
  height: auto;
}

.learn-driving_search-subscript {
  align-items: center;
  color: #7d7d7d;
  font-family: 'Nunito';
  font-style: italic;
  font-weight: 400;
}

/* Css for container 1 ends */

/* Css for container 2 starts */
.learn-driving_container-2 {
  background: var(--tertiary-bg);
}
.learn-driving_tertiary-button {
  background: var(--tertiary);
  box-shadow: 0rem 0.25rem 0.625rem rgba(7, 71, 149, 0.2);
  border-radius: 0.625rem;
  color: white;
  font-style: normal;
  font-weight: 500;
}
/* Css for container 2 ends */
