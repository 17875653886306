.driving-school-profile__about {
  background: #fff5e2;
}
.driving-school-profile__about-name {
  color: var(--tertiary-bg);
  top: -0.938rem;
  left: 4rem;
  width: fit-content;
  height: auto;
  background-color: var(--tertiary);
  border-radius: 0rem 0rem 0.625rem 0.625rem;
  z-index: 1;
}
@media screen and (max-width: 992px) {
  .driving-school-profile__about-name {
    left: 0;
    right: 0;
    width: 18rem;
    margin-left: auto;
    margin-right: auto;
  }
}
.driving-school-profile__hero_image {
  width: stretch;
  height: 10rem;
  max-width: 12rem;
  object-fit: cover;
}
.driving-school-profile__about-description {
  color: var(--tertiary);
}
.driving-school-profile__about-profile {
  width: stretch;
  max-width: 12rem;
  max-height: 15rem;
}

.driving-school-profile__about-profile-name {
  left: 0rem;
  bottom: 0rem;
  width: 100%;
}
.driving-school-profile__about-profile-name img {
  left: 0rem;
  width: 100%;
}
.driving-school-profile__about-profile-rating {
  top: 0.875rem;
  left: 0rem;
  background-image: url(assets/profile/ratingBackground2.svg);
  width: 100%;
  color: white;
  background-repeat: no-repeat;
}
.driving-school-profile__about-profile-rating img {
  height: 1rem;
}

.driving-school-profile__about-btn-contact-us {
  background: #28a617 !important;
  color: white !important;
}
.driving-school-profile__about-btn-contact-us-arrow {
  width: 1rem;
  height: auto;
}

.driving-school-profile__slider_img {
  width: stretch;
  height: 20.5625rem;
  object-fit: cover;
  overflow: hidden;
}
@media screen and (max-width: 1200px) {
  .driving-school-profile__slider_img {
    height: 20.5625rem;
  }
}
@media screen and (max-width: 992px) {
  .driving-school-profile__slider_img {
    height: 20.5625rem;
  }
}
@media screen and (max-width: 768px) {
  .driving-school-profile__slider_img {
    height: 18.5625rem;
  }
}
@media screen and (max-width: 576px) {
  .driving-school-profile__slider_img {
    height: 16.5625rem;
  }
}
@media screen and (max-width: 450px) {
  .driving-school-profile__slider_img {
    height: 12.5625rem;
  }
}
