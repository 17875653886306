.contact-us_title {
  background: var(--primary);
}
.contact-us_body {
  font-family: 'Poppins';
}
.contact-us_social-icon {
  background: #d9d9d9;
  border-radius: 0.3125rem;
  margin-right: 0.5rem;
  padding: 0.6rem;
}
